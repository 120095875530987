import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/routing';
import {
  CurrentPickingRoute,
  CurrentPickingSession,
  CurrentPickingState,
  FullBasket,
  FullOrder,
  Order,
  OrderWithItems,
} from '@models/Order';

type RoutingType = ActionType<typeof types>;

interface OrdersState {
  list: Order[];
  error?: object;
  loading: boolean;
}
interface FullBasketState {
  basket?: FullBasket;
  noBasket: boolean;
  error?: object;
  loading: boolean;
}
interface PickSessionState {
  session?: CurrentPickingSession;
  error?: object;
  loading: boolean;
}
interface OrderState {
  order?: OrderWithItems;
  error?: object;
  loading: boolean;
}
interface LoadingState {
  error?: object;
  loading: boolean;
}
interface LoadingStateWithFeedback {
  error?: object;
  loading: boolean;
  complete: boolean;
}
interface ConfirmedState {
  error?: object;
  loading: boolean;
  confirmed: boolean;
}
interface QuickOrderState {
  error?: object;
  loading: boolean;
  order?: FullOrder;
}
interface PickingSessionState {
  error?: object;
  loading: boolean;
  pickingSessionId?: string;
}
interface PickingTaskSessionState {
  error?: object;
  loading: boolean;
  barcode?: string;
  pickingTaskId?: string;
  locationId?: string;
}
interface ItemSessionState {
  error?: object;
  loading: boolean;
  itemName?: string;
  requiredQuantity?: number;
}
interface CurrentRouteState {
  error?: object;
  loading: boolean;
  currentRoute?: CurrentPickingRoute;
}
interface CurrentSessionState {
  error?: object;
  loading: boolean;
  currentState?: CurrentPickingState;
}
export interface IRoutingState {
  readonly currentBasket: FullBasketState;
  readonly currentSession: PickSessionState;
  readonly abandonBasket: LoadingState;
  readonly checkoutBasket: LoadingState;
  readonly removeBasketOrders: LoadingState;
  readonly orders: OrdersState;
  readonly order: OrderState;
  readonly basketOrders: LoadingState;
  readonly autoLocate: LoadingState;
  readonly quickOrder: QuickOrderState;
  readonly validateItemLocation: PickingTaskSessionState;
  readonly confirmValidateItemLocation: ConfirmedState;
  readonly validateLocationComplete: PickingTaskSessionState;
  readonly confirmValidateLocationComplete: ConfirmedState;
  readonly validateItemQuantity: ItemSessionState;
  readonly confirmValidateItemQuantity: ConfirmedState;
  readonly completeItemPick: ConfirmedState;
  readonly startPickingSession: PickingSessionState;
  readonly stopPickingSession: LoadingStateWithFeedback;
  readonly suspendOrder: LoadingStateWithFeedback;
  readonly validateToteComplete: LoadingStateWithFeedback;
  readonly completeOrder: LoadingStateWithFeedback;
  readonly currentRoute: CurrentRouteState;
  readonly currentState: CurrentSessionState;
}

export const initialState: IRoutingState = {
  currentSession: {
    loading: false,
  },
  currentBasket: {
    noBasket: false,
    loading: false,
  },
  abandonBasket: {
    loading: false,
  },
  checkoutBasket: {
    loading: false,
  },
  removeBasketOrders: {
    loading: false,
  },
  orders: {
    list: [],
    loading: false,
  },
  order: {
    loading: false,
  },
  basketOrders: {
    loading: false,
  },
  autoLocate: {
    loading: false,
  },
  quickOrder: {
    loading: false,
  },
  validateItemLocation: {
    loading: false,
  },
  confirmValidateItemLocation: {
    confirmed: false,
    loading: false,
  },
  validateLocationComplete: {
    loading: false,
  },
  confirmValidateLocationComplete: {
    confirmed: false,
    loading: false,
  },
  validateItemQuantity: {
    loading: false,
  },
  confirmValidateItemQuantity: {
    confirmed: false,
    loading: false,
  },
  completeItemPick: {
    confirmed: false,
    loading: false,
  },
  startPickingSession: {
    loading: false,
  },
  stopPickingSession: {
    loading: false,
    complete: false,
  },
  suspendOrder: {
    loading: false,
    complete: false,
  },
  validateToteComplete: {
    loading: false,
    complete: false,
  },
  completeOrder: {
    loading: false,
    complete: false,
  },
  currentRoute: {
    loading: false,
  },
  currentState: {
    loading: false,
  },
};

export default (
  state: IRoutingState = initialState,
  action: RoutingType
): IRoutingState => {
  switch (action.type) {
    case getType(types.fetchOrders.request): {
      const { shallow } = action.payload;

      return {
        ...state,
        orders: {
          ...state.orders,
          loading: shallow ? state.orders?.loading : true,
          error: undefined,
        },
      };
    }

    case getType(types.fetchOrders.success): {
      const { orders, shallow } = action.payload;

      return {
        ...state,
        orders: {
          ...state.orders,
          list: orders,
          loading: shallow ? state.orders?.loading : false,
          error: undefined,
        },
      };
    }

    case getType(types.fetchOrders.failure): {
      const { error, shallow } = action.payload;

      return {
        ...state,
        orders: {
          ...state.orders,
          loading: shallow ? state.orders?.loading : false,
          error,
        },
      };
    }

    case getType(types.fetchOrderItems.request): {
      return {
        ...state,
        order: {
          ...state.order,
          order: undefined,
          loading: true,
          error: undefined,
        },
      };
    }

    case getType(types.fetchOrderItems.success): {
      const { order } = action.payload;

      return {
        ...state,
        order: {
          ...state.order,
          order,
          loading: false,
          error: undefined,
        },
      };
    }

    case getType(types.fetchOrderItems.failure): {
      const { error } = action.payload;

      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
          error,
        },
      };
    }

    case getType(types.addOrderToCurrentBasket.request): {
      return {
        ...state,
        basketOrders: {
          ...state.basketOrders,
          loading: true,
        },
      };
    }

    case getType(types.addOrderToCurrentBasket.success): {
      return {
        ...state,
        basketOrders: {
          ...state.basketOrders,
          loading: false,
        },
      };
    }

    case getType(types.addOrderToCurrentBasket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        basketOrders: {
          ...state.basketOrders,
          loading: false,
          error,
        },
      };
    }

    case getType(types.addOrderToNewBasket.request): {
      return {
        ...state,
        basketOrders: {
          ...state.basketOrders,
          loading: true,
        },
      };
    }

    case getType(types.addOrderToNewBasket.success): {
      return {
        ...state,
        basketOrders: {
          ...state.basketOrders,
          loading: false,
        },
      };
    }

    case getType(types.addOrderToNewBasket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        basketOrders: {
          ...state.basketOrders,
          loading: false,
          error,
        },
      };
    }

    case getType(types.abandonBasket.request): {
      return {
        ...state,
        abandonBasket: {
          ...state.abandonBasket,
          loading: true,
        },
      };
    }

    case getType(types.abandonBasket.success): {
      return {
        ...state,
        currentBasket: {
          ...state.currentBasket,
          noBasket: true,
          basket: undefined,
        },
        abandonBasket: {
          ...state.abandonBasket,
          loading: false,
        },
      };
    }

    case getType(types.abandonBasket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        abandonBasket: {
          ...state.abandonBasket,
          loading: false,
          error,
        },
      };
    }

    case getType(types.checkoutBasket.request): {
      return {
        ...state,
        checkoutBasket: {
          ...state.checkoutBasket,
          loading: true,
        },
      };
    }

    case getType(types.checkoutBasket.success): {
      return {
        ...state,
        currentBasket: {
          ...state.currentBasket,
          noBasket: true,
          basket: undefined,
        },
        checkoutBasket: {
          ...state.checkoutBasket,
          loading: false,
        },
      };
    }

    case getType(types.checkoutBasket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        checkoutBasket: {
          ...state.checkoutBasket,
          loading: false,
          error,
        },
      };
    }

    case getType(types.fetchCurrentSession.request): {
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          session: undefined,
          loading: true,
          error: undefined,
        },
      };
    }

    case getType(types.fetchCurrentSession.success): {
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          session: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case getType(types.fetchCurrentSession.failure): {
      const { error } = action.payload;

      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          loading: false,
          error,
        },
      };
    }

    case getType(types.fetchCurrentBasket.request): {
      return {
        ...state,
        currentBasket: {
          ...state.currentBasket,
          basket: undefined,
          noBasket: true,
          loading: true,
          error: undefined,
        },
      };
    }

    case getType(types.fetchCurrentBasket.success): {
      return {
        ...state,
        currentBasket: {
          ...state.currentBasket,
          basket: action.payload,
          noBasket: false,
          loading: false,
          error: undefined,
        },
      };
    }

    case getType(types.fetchCurrentBasket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        currentBasket: {
          ...state.currentBasket,
          loading: false,
          error,
        },
      };
    }

    case getType(types.removeBasketOrders.request): {
      return {
        ...state,
        removeBasketOrders: {
          ...state.removeBasketOrders,
          loading: true,
          error: undefined,
        },
      };
    }

    case getType(types.removeBasketOrders.success): {
      const { orderIds } = action.payload;

      return {
        ...state,
        removeBasketOrders: {
          ...state.removeBasketOrders,
          loading: false,
        },
        currentBasket: {
          ...state.currentBasket,
          basket: {
            ...(state.currentBasket.basket || {} as FullBasket),
            orders: (state.currentBasket.basket?.orders || []).filter((o) => orderIds.indexOf(o.id) === -1),
          },
        },
      };
    }

    case getType(types.removeBasketOrders.failure): {
      const { error } = action.payload;

      return {
        ...state,
        removeBasketOrders: {
          ...state.removeBasketOrders,
          loading: false,
          error,
        },
      };
    }

    case getType(types.autoLocate.request): {
      return {
        ...state,
        autoLocate: {
          ...state.autoLocate,
          loading: true,
        },
      };
    }

    case getType(types.autoLocate.success): {
      return {
        ...state,
        autoLocate: {
          ...state.autoLocate,
          loading: false,
        },
      };
    }

    case getType(types.autoLocate.failure): {
      const { error } = action.payload;

      return {
        ...state,
        autoLocate: {
          ...state.autoLocate,
          loading: false,
          error,
        },
      };
    }

    case getType(types.quickOrder.request): {
      return {
        ...state,
        quickOrder: {
          ...state.quickOrder,
          loading: true,
        },
      };
    }

    case getType(types.quickOrder.success): {
      return {
        ...state,
        quickOrder: {
          ...state.quickOrder,
          order: action.payload,
          loading: false,
        },
      };
    }

    case getType(types.quickOrder.failure): {
      const { error } = action.payload;

      return {
        ...state,
        quickOrder: {
          ...state.quickOrder,
          loading: false,
          error,
        },
      };
    }

    case getType(types.validateItemLocation.request): {
      return {
        ...state,
        validateItemLocation: {
          ...state.validateItemLocation,
          loading: true,
        },
      };
    }

    case getType(types.validateItemLocation.success): {
      const { pickingTaskId, locationId, barcode } = action.payload;

      return {
        ...state,
        validateItemLocation: {
          ...state.validateItemLocation,
          barcode,
          pickingTaskId,
          locationId,
          loading: false,
        },
      };
    }

    case getType(types.validateItemLocation.failure): {
      const { error } = action.payload;

      return {
        ...state,
        validateItemLocation: {
          ...state.validateItemLocation,
          loading: false,
          error,
        },
      };
    }

    case getType(types.confirmValidateItemLocation.request): {
      return {
        ...state,
        confirmValidateItemLocation: {
          ...state.confirmValidateItemLocation,
          loading: true,
          confirmed: false,
        },
      };
    }

    case getType(types.confirmValidateItemLocation.success): {
      return {
        ...state,
        confirmValidateItemLocation: {
          ...state.confirmValidateItemLocation,
          loading: false,
          confirmed: true,
        },
      };
    }

    case getType(types.confirmValidateItemLocation.failure): {
      const { error } = action.payload;

      return {
        ...state,
        confirmValidateItemLocation: {
          ...state.confirmValidateItemLocation,
          loading: false,
          error,
        },
      };
    }

    case getType(types.validateLocationComplete.request): {
      return {
        ...state,
        validateLocationComplete: {
          ...state.validateLocationComplete,
          loading: true,
        },
      };
    }

    case getType(types.validateLocationComplete.success): {
      const { pickingTaskId, locationId, barcode } = action.payload;

      return {
        ...state,
        validateLocationComplete: {
          ...state.validateLocationComplete,
          barcode,
          pickingTaskId,
          locationId,
          loading: false,
        },
      };
    }

    case getType(types.validateLocationComplete.failure): {
      const { error } = action.payload;

      return {
        ...state,
        validateLocationComplete: {
          ...state.validateLocationComplete,
          loading: false,
          error,
        },
      };
    }

    case getType(types.confirmValidateLocationComplete.request): {
      return {
        ...state,
        confirmValidateLocationComplete: {
          ...state.confirmValidateLocationComplete,
          loading: true,
          confirmed: false,
        },
      };
    }

    case getType(types.confirmValidateLocationComplete.success): {
      return {
        ...state,
        confirmValidateLocationComplete: {
          ...state.confirmValidateLocationComplete,
          loading: false,
          confirmed: true,
        },
      };
    }

    case getType(types.confirmValidateLocationComplete.failure): {
      const { error } = action.payload;

      return {
        ...state,
        confirmValidateLocationComplete: {
          ...state.confirmValidateLocationComplete,
          loading: false,
          error,
        },
      };
    }

    case getType(types.validateItemQuantity.request): {
      return {
        ...state,
        validateItemQuantity: {
          ...state.validateItemQuantity,
          loading: true,
        },
      };
    }

    case getType(types.validateItemQuantity.success): {
      const { itemName, requiredQuantity } = action.payload;

      return {
        ...state,
        validateItemQuantity: {
          ...state.validateItemQuantity,
          itemName,
          requiredQuantity,
          loading: false,
        },
      };
    }

    case getType(types.validateItemQuantity.failure): {
      const { error } = action.payload;

      return {
        ...state,
        validateItemQuantity: {
          ...state.validateItemQuantity,
          loading: false,
          error,
        },
      };
    }

    case getType(types.confirmValidateItemQuantity.request): {
      return {
        ...state,
        confirmValidateItemQuantity: {
          ...state.confirmValidateItemQuantity,
          confirmed: false,
          loading: true,
        },
      };
    }

    case getType(types.confirmValidateItemQuantity.success): {
      return {
        ...state,
        confirmValidateItemQuantity: {
          ...state.confirmValidateItemQuantity,
          confirmed: true,
          loading: false,
        },
      };
    }

    case getType(types.confirmValidateItemQuantity.failure): {
      const { error } = action.payload;

      return {
        ...state,
        confirmValidateItemQuantity: {
          ...state.confirmValidateItemQuantity,
          loading: false,
          error,
        },
      };
    }

    case getType(types.completeItemPick.request): {
      return {
        ...state,
        completeItemPick: {
          ...state.completeItemPick,
          confirmed: false,
          loading: true,
        },
      };
    }

    case getType(types.completeItemPick.success): {
      return {
        ...state,
        completeItemPick: {
          ...state.completeItemPick,
          confirmed: true,
          loading: false,
        },
      };
    }

    case getType(types.completeItemPick.failure): {
      const { error } = action.payload;

      return {
        ...state,
        completeItemPick: {
          ...state.completeItemPick,
          loading: false,
          error,
        },
      };
    }

    case getType(types.startPickingSession.request): {
      return {
        ...state,
        startPickingSession: {
          ...state.startPickingSession,
          loading: true,
          pickingSessionId: undefined,
        },
      };
    }

    case getType(types.startPickingSession.success): {
      const { id } = action.payload;

      return {
        ...state,
        startPickingSession: {
          ...state.startPickingSession,
          pickingSessionId: id,
          loading: false,
        },
      };
    }

    case getType(types.startPickingSession.failure): {
      const { error } = action.payload;

      return {
        ...state,
        startPickingSession: {
          ...state.startPickingSession,
          loading: false,
          error,
        },
      };
    }

    case getType(types.stopPickingSession.request): {
      return {
        ...state,
        stopPickingSession: {
          ...state.stopPickingSession,
          loading: true,
          complete: false,
        },
      };
    }

    case getType(types.stopPickingSession.success): {
      return {
        ...state,
        stopPickingSession: {
          ...state.stopPickingSession,
          loading: false,
          complete: true,
        },
      };
    }

    case getType(types.stopPickingSession.failure): {
      const { error } = action.payload;

      return {
        ...state,
        stopPickingSession: {
          ...state.stopPickingSession,
          loading: false,
          error,
        },
      };
    }

    case getType(types.suspendOrder.request): {
      return {
        ...state,
        suspendOrder: {
          ...state.suspendOrder,
          loading: true,
          complete: false,
        },
      };
    }

    case getType(types.suspendOrder.success): {
      return {
        ...state,
        suspendOrder: {
          ...state.suspendOrder,
          loading: false,
          complete: true,
        },
      };
    }

    case getType(types.suspendOrder.failure): {
      const { error } = action.payload;

      return {
        ...state,
        suspendOrder: {
          ...state.suspendOrder,
          loading: false,
          error,
        },
      };
    }

    case getType(types.validateToteComplete.request): {
      return {
        ...state,
        validateToteComplete: {
          ...state.validateToteComplete,
          loading: true,
          complete: false,
        },
      };
    }

    case getType(types.validateToteComplete.success): {
      return {
        ...state,
        validateToteComplete: {
          ...state.validateToteComplete,
          loading: false,
          complete: true,
        },
      };
    }

    case getType(types.validateToteComplete.failure): {
      const { error } = action.payload;

      return {
        ...state,
        validateToteComplete: {
          ...state.validateToteComplete,
          loading: false,
          error,
        },
      };
    }

    case getType(types.completeOrder.request): {
      return {
        ...state,
        completeOrder: {
          ...state.completeOrder,
          loading: true,
          complete: false,
        },
      };
    }

    case getType(types.completeOrder.success): {
      return {
        ...state,
        completeOrder: {
          ...state.completeOrder,
          loading: false,
          complete: true,
        },
      };
    }

    case getType(types.completeOrder.failure): {
      const { error } = action.payload;

      return {
        ...state,
        completeOrder: {
          ...state.completeOrder,
          loading: false,
          error,
        },
      };
    }

    case getType(types.fetchCurrentRoute.request): {
      return {
        ...state,
        currentState: {
          ...state.currentState,
          currentState: undefined,
        },
        currentRoute: {
          ...state.currentRoute,
          currentRoute: undefined,
          loading: true,
        },
      };
    }

    case getType(types.fetchCurrentRoute.success): {
      const currentRoute = action.payload;

      return {
        ...state,
        currentRoute: {
          ...state.currentRoute,
          currentRoute,
          loading: false,
        },
      };
    }

    case getType(types.fetchCurrentRoute.failure): {
      const { error } = action.payload;

      return {
        ...state,
        currentRoute: {
          ...state.currentRoute,
          loading: false,
          error,
        },
      };
    }
    
    case getType(types.wsRoute): {
      const route = action.payload;

      return {
        ...state,
        currentRoute: {
          ...state.currentRoute,
          currentRoute: {
            ...((state.currentRoute || {}).currentRoute || {} as CurrentPickingRoute),
            route,
          },
        },
      };
    }

    case getType(types.pickingSessionState.request): {
      return {
        ...state,
        currentState: {
          ...state.currentState,
          currentState: undefined,
          loading: true,
        },
      };
    }

    case getType(types.pickingSessionState.success): {
      const currentState = action.payload;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          currentState,
          loading: false,
        },
      };
    }

    case getType(types.pickingSessionState.failure): {
      const { error } = action.payload;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          loading: false,
          error,
        },
      };
    }

    default:
      return state;
  }
};
